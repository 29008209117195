import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { getStyles } from "./EditOffer.style";
import Section from "../../Components/Section/Section";
import { FaAngleRight, FaArrowRight, FaRegClock, FaSort } from "react-icons/fa";
import { All_Product_List, Create_Offers, Edit_Offer, My_Store, getOfferDetails } from "../../Api";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface OfferPayload {
  store: string | null;
  offer_type: any;
  product: number;
  send_per: any;
  status: boolean; // Change status to boolean
  valid_from: any;
  valid_to: any;
  buy_quantity?: number | null;
  free_quantity?: number | null;
  free_product?: number | null;
}


const EditOffer: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const breadcrumbs: string[] = ["Home", "Offers"];
  interface Category {
    id: string;
    product_name: string;
    PRDISactive: string;

  }

  const [offer, setOffer] = useState("");
  const [couponType, setCouponType] = useState("");
  const [product, setProduct] = useState<any>([]);
  const [productType, setProductType] = useState("");
  const [limit, setLimit] = useState("");
  const [amount, setAmount] = useState("");
  const [percent, setPercent] = useState("");
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [sendPer, setSendPer] = useState("");
  const [storeId, setStoreId] = useState<string | null>("");
  const [userToken, setUserToken] = useState<string | null>("");
  const [selectedOffers, setSelectedOffers] = useState("");
  const [buyQuantity, setBuyQuantity] = useState("");
  const [freeQuantity, setFreeQuantity] = useState("");
  const [freeProduct, setFreeProduct] = useState("");
  const [status, setStatus] = useState("");

  const [errorss, setErrorss] = useState({
    limit: "",
    amount: "",
    percent: "",
    bogo: "",
    buyQuantity: "",
    freeQuantity: ""
  });
  const navigate = useNavigate();
  const selectedOffer = useSelector((state: RootState) => state.offer.selectedOffer);
  const languageId: any = useSelector((state: RootState) => state.lan);
  const id = selectedOffer?.id


  const schema = yup.object().shape({
    sendPer: yup.string().required("Send per is mandatory"),
    validFrom: yup.string().required("* Date is mandatory"),
    validTo: yup.string().required("* Date is required"),
    productType: yup.string().required("* Select an option"),
    offer: yup.string().required("* Select an option"),
    status: yup.string().oneOf(["active", "inactive"]).required(), // Add this line
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: {
    //   productName: 'Default Product Name', // Set your default value here
    // }
  });


  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (userToken) {
      const handleAPI = async () => {
        try {
          const response = await My_Store(userToken);
          if (response && response.status === 200) {
            setStoreId(response.data.my_stores[0].id);
          } else {
            console.log("Error fetching My Store details");
          }
        } catch (error) {
          console.log("Error fetching My Store details", error);
        }
      };
      handleAPI();
    }
  }, [userToken]);

  useEffect(() => {
    if (storeId) {
      fetchStoreList();
    }
  }, [storeId]);

  const fetchStoreList = async () => {
    try {
      const response = await All_Product_List(storeId);
      if (response && response.status === 200) {
        setProduct(response.data.store_product_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  };

  const handleOfferChange = (value: any) => {
    setSelectedOffers(value);
    console.log(value, "value========>")
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    console.log(value, "words======>")
    setLimit(value)

    const numRegex = /^[0-9]+(\.[0-9]+)?$/

    if (!numRegex.test(value)) {
      setErrorss((errorss) => ({
        ...errorss,
        limit: "* Invalid value",
      }));
    } else {
      setErrorss((errorss) => ({
        ...errorss,
        limit: "",
      }));
    }
  }

  const handleAmount = (e: any) => {
    const value = e.target.value;
    setAmount(value)

    if (!value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      setErrorss((errorss) => ({
        ...errorss,
        amount: "* Invalid value",
      }));
    } else {
      setErrorss((errorss) => ({
        ...errorss,
        amount: "",
      }));
    }
  }

  const handlePercent = (e: any) => {
    const value = e.target.value;
    setPercent(value)

    if (!value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      setErrorss((errorss) => ({
        ...errorss,
        percent: "* Invalid value",
      }));
    } else {
      setErrorss((errorss) => ({
        ...errorss,
        percent: "",
      }));
    }
  }


  useEffect(() => {
    if (userToken) {
      handleGetOfferDetails(userToken, selectedOffer?.id)
    }
  }, [userToken]);

  useEffect(() => {
    if (storeId) {
      fetchStoreList();
    }
  }, [storeId]);

  const handleGetOfferDetails = async (userToken: any, offerID: any) => {
    try {
      const response = await getOfferDetails(userToken, offerID);
      if (response && response.status === 200) {
        console.log(response.data.offer_details);
        const ofer = response.data.offer_details
        // setOffer(ofer.offer_type)
        setSelectedOffers(ofer?.offer_type)
        // setProductType(ofer.product.id)
        setPercent(ofer?.percent)
        setAmount(ofer?.amount)
        setLimit(ofer?.limited)
        setBuyQuantity(ofer?.buy_quantity)
        setFreeQuantity(ofer?.free_quantity)
        setFreeProduct(ofer?.free_product)
        const offerStatus = ofer.status ? "active" : "inactive"; // Convert boolean to string


        reset({
          offer: ofer?.offer_type ?? "",
          productType: ofer?.product.id ?? "",
          validFrom: ofer?.valid_from ?? "",
          validTo: ofer?.valid_to ?? "",
          sendPer: ofer?.send_per ?? "",
          status: offerStatus
        })


      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  }



  const handleFormSubmit = (data: any) => {
    // Check for required fields if `offer_type` is "bogo"
    if (data.offer === "bogo" && !freeProduct) {
      setFreeProductError(true); // Show error if free product is not selected
      return; // Prevent submission
    }
    

  
  
    // Create a base payload object
    let payload: OfferPayload = {
      store: storeId,
      offer_type: data.offer,
      product: parseInt(data.productType),
      send_per: data.sendPer,
      status: data.status === "active", // true if "active", false if "inactive"
      valid_from: data.validFrom,
      valid_to: data.validTo,
    };
  
    // Add conditional fields for "bogo" offer type
    if (data.offer === "bogo") {
      payload = {
        ...payload,
        buy_quantity: buyQuantity ? parseInt(buyQuantity.toString()) : null,
        free_quantity: freeQuantity ? parseInt(freeQuantity.toString()) : null,
        free_product: freeProduct ? parseInt(freeProduct.toString()) : null,
      };
    }
  
    // Call Edit_Offer with the full payload and additional data
    const EditOffer = async () => {
      try {
        const response = await Edit_Offer(
          id,
          userToken,
          storeId,
          payload, // Pass the complete payload
          amount,
          limit,
          percent,
          buyQuantity,
          freeQuantity,
          freeProduct
        );
  
        if (response && response.status === 201) {
          toast.success(response.data.message);
          navigate("/offers");
        } else {
          console.log(response.data, "Response data for debugging");
        }
      } catch (error: any) {
        console.log("Error Editing Offer", error);
        toast.error(error.response?.data?.message || "An error occurred");
      }      
    };
  
    // Call the EditOffer function
    EditOffer();
  };
  
  const handleBuyQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBuyQuantity(e.target.value);

    const value = e.target.value;
    if (!value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      setErrorss((errorss) => ({
        ...errorss,
        buyQuantity: "* Invalid value",
      }));
    } else {
      setErrorss((errorss) => ({
        ...errorss,
        buyQuantity: "",
      }));
    }
  };

  const handleFreeQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFreeQuantity(e.target.value);

    const value = e.target.value;
    if (!value.match(/^[0-9]+(\.[0-9]+)?$/)) {
      setErrorss((errorss) => ({
        ...errorss,
        freeQuantity: "* Invalid value",
      }));
    } else {
      setErrorss((errorss) => ({
        ...errorss,
        freeQuantity: "",
      }));
    }
  };
  const [freeProductError, setFreeProductError] = useState(false);

  const handleFreeProductChange = (e: React.ChangeEvent<any>) => {
    const selectedValue = e.target.value; // Extract value from event target
    console.log("selected Value", selectedValue);
    setFreeProduct(selectedValue);
    setFreeProductError(!selectedValue); // Check if the value is empty
  };
  const statusString = status ? "active" : "inactive"; // Converts true to "active", false to "inactive"


  return (
    <>
      <Section className="createOffer" style={styles.bg}>
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section className="createOffer" style={{ marginTop: -30 }}>
        <div style={styles.whiteWrap}>
          <Container>
            <Form onSubmit={handleSubmit(handleFormSubmit)}>
              <Form.Group as={Row} controlId="formOffer">
                <Form.Label column sm={12}>
                  {t("offerList.offer")}
                </Form.Label>
                <Controller
                  name="offer"
                  control={control}
                  defaultValue=""
                 
                  render={({ field }) => (
                    <Form.Control
                      as="select"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        handleOfferChange(e.target.value);
                      }}
                      disabled
                    >
                      <option disabled value="">
                        {t("offerList.selectAnOffer")}
                      </option>
                      <option value="amount">{t("offerList.amount")}</option>
                      <option value="limited">{t("offerList.limited")}</option>
                      <option value="percent">{t("offerList.percent")}</option>
                      <option value="bogo">By One Get One Free</option>
                    </Form.Control>
                  )}
                />
                {/* <Col sm={12}>
                <Form.Control
                  as="select"
                  value={offer}
                  onChange={(e) => setOffer(e.target.value)}
                  // {...register("offer")}
                >
                  <option disabled value="">
                    Select an offer
                  </option>
                  <option value="amount">Amount</option>
                  <option value="limited">Limited</option>
                  <option value="percent">Percent</option>
                  {/* Add options dynamically */}
                {errors.offer && (
                  <span style={styles.error}>
                    {errors.offer.message}
                  </span>
                )}
              </Form.Group>

              {/* <Form.Group as={Row} controlId="formCouponType">
                              <Form.Label column sm={12}>
                                  Coupon Type
                              </Form.Label>
                              <Col sm={12}>
                                  <Form.Check
                                      inline
                                      type="radio"
                                      label="Reusable"
                                      name="couponType"
                                      value="reusable"
                                      checked={couponType === 'reusable'}
                                      onChange={(e) => setCouponType(e.target.value)}
                                  />
                                  <Form.Check
                                      inline
                                      type="radio"
                                      label="Unique"
                                      name="couponType"
                                      value="unique"
                                      checked={couponType === 'unique'}
                                      onChange={(e) => setCouponType(e.target.value)}
                                  />
                              </Col>
                          </Form.Group> */}

              <Form.Group as={Row} controlId="formProduct">
                <Form.Label column sm={12}>
                  {t("offerList.selectProduct")}
                </Form.Label>
                <Controller
                  name="productType"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Form.Control
                      as="select"
                      {...field}
                    >
                      <option value="">{t("offerList.selectProduct")}</option>
                      {product &&
                        product.map((item: any) => {
                          if (item.PRDISactive) {
                            return (
                              <option value={item.id}>{item?.language_data.length === 0
                                ? item?.product_name
                                : languageId === "ne"
                                  ? item?.language_data[0]?.product_name
                                  : item?.product_name}</option>
                            );
                          }
                        })}
                    </Form.Control>
                  )}
                />
                {errors.productType && (
                  <span style={styles.error}>
                    {errors.productType.message}
                  </span>
                )}
                {/* <Col sm={12}>
                <Form.Control
                  as="select"
                  value={productType}
                  onChange={(e) => setProductType(e.target.value)}
                >
                  <option value="">Select a product</option>
                  {product &&
                    product.map((item) => {
                        if(item.PRDISactive){
                      return (
                        <option value={item.id}>{item.product_name}</option>
                      );
                        }
                    })}
                  {/* Add options dynamically */}
              </Form.Group>

              {selectedOffers === "limited" ? (
                <Form.Group as={Row} controlId="formLimit">
                  <Form.Label column sm={12}>
                    {t("offerList.limit")}
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      placeholder={t("offerList.enterLimit")}
                      value={limit}
                      onChange={(e) => handleChange(e)}
                      required
                    />
                  </Col>
                  {errorss && (
                    <span style={styles.error}>
                      {errorss.limit}
                    </span>
                  )}
                </Form.Group>
              ) : selectedOffers === "amount" ? (
                <Form.Group as={Row} controlId="formAmount">
                  <Form.Label column sm={12}>
                    {t("offerList.amount")}
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      placeholder={t("offerList.enterAmount")}
                      value={amount}
                      onChange={(e) => handleAmount(e)}
                      required
                    />
                  </Col>
                  {errorss && (
                    <span style={styles.error}>
                      {errorss.amount}
                    </span>
                  )}
                </Form.Group>
              ) : selectedOffers === "percent" ? (
                <Form.Group as={Row} controlId="formPercent">
                  <Form.Label column sm={12}>
                    {t("offerList.percent")}
                  </Form.Label>
                  <Col sm={12}>
                    <Form.Control
                      type="text"
                      placeholder={t("offerList.enterPercent")}
                      value={percent}
                      onChange={(e) => handlePercent(e)}
                      required
                    />
                  </Col>
                  {errorss && (
                    <span style={styles.error}>
                      {errorss.percent}
                    </span>
                  )}
                </Form.Group>
              ) : selectedOffers === "bogo" ? (
                <>
                  <Form.Group as={Row} controlId="formBogo">
                    <Form.Label column sm={12}>
                      Buy Quantity
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        placeholder="Enter Buy Quantity"
                        value={buyQuantity}
                        onChange={handleBuyQuantityChange}
                        required
                      />
                    </Col>
                    {errorss && (
                      <span style={styles.error}>
                        {errorss.buyQuantity}
                      </span>
                    )}
                  </Form.Group>
                  <Form.Group as={Row} controlId="formBogo">
                    <Form.Label column sm={12}>
                      Free Quantity
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        type="text"
                        placeholder="Enter Free Quantity"
                        value={freeQuantity}
                        onChange={handleFreeQuantityChange}
                        required
                      />
                    </Col>
                    {errorss && (
                      <span style={styles.error}>
                        {errorss.freeQuantity}
                      </span>
                    )}
                  </Form.Group>

                  <Form.Group as={Row} controlId="formFreeProduct">
                    <Form.Label column sm={12}>
                      Select Free Product
                    </Form.Label>
                    <Col sm={12}>
                      <Form.Control
                        as="select"
                        value={freeProduct}
                        onChange={handleFreeProductChange}
                        isInvalid={freeProductError} // Highlight field as invalid
                      >
                        <option value="">Select a Product</option>
                        {product &&
                        product.map((item: any) => {
                          if (item.PRDISactive) {
                            return (
                              <option value={item.id}>{item?.language_data.length === 0
                                ? item?.product_name
                                : languageId === "ne"
                                  ? item?.language_data[0]?.product_name
                                  : item?.product_name}</option>
                            );
                          }
                        })}
                      </Form.Control>
                      {freeProductError && (
                        <Form.Text style={styles.error}>
                          Please select a free product.
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>


                </>
              ) : null}

              <Form.Group as={Row} controlId="formValidFrom">
                <Form.Label column sm={12}>
                  {t("offerList.validFrom")}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type="date"
                    {...register("validFrom")}
                  />
                </Col>
                {errors.validFrom && (
                  <span style={styles.error}>
                    {errors.validFrom.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group as={Row} controlId="formValidTo">
                <Form.Label column sm={12}>
                  {t("offerList.validTo")}
                </Form.Label>
                <Col sm={12}>
                  <Form.Control
                    type="date"
                    {...register("validTo")}
                  />
                </Col>
                {errors.validTo && (
                  <span style={styles.error}>
                    {errors.validTo.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group as={Row} controlId="formSendPer">
                <Form.Label column sm={12}>
                  {t("offerList.sendPer")}
                </Form.Label>
                <Col sm={12}>
                  <Controller
                    name="sendPer"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <>
                        <Form.Check
                          inline
                          type="radio"
                          label={t("offerList.firstOrderOnly")}
                          name="sendPer"
                          value="first_order"
                          checked={field.value === "first_order"}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setSendPer(e.target.value);
                          }}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          label={t("offerList.everyOrder")}
                          name="sendPer"
                          value="every_order"
                          checked={field.value === "every_order"}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setSendPer(e.target.value);
                          }}
                        />
                      </>
                    )}
                  />
                  {errors.sendPer && (
                    <span style={{ color: 'red' }}>
                      {errors.sendPer.message}
                    </span>
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formOfferStatus">
  <Form.Label column sm={12}>
    Offer Status
  </Form.Label>
  <Col sm={12}>
  
  <Controller
  name="status"
  control={control}
  defaultValue={statusString} // Set the default value as "active" or "inactive"
  render={({ field }) => (
    <Form.Check
      type="switch"
      id="offer-status-switch"
      label={field.value === "active" ? "Active" : "Inactive"} // Display as "Active" or "Inactive"
      checked={field.value === "active"} // Convert "active" to true for checked state
      onChange={(e) => {
        const newStatus = e.target.checked ? "active" : "inactive"; // Convert true/false to "active"/"inactive"
        field.onChange(newStatus); // Pass the converted value to the form field
      }}
    />
  )}
/>

  </Col>
</Form.Group>














              <Row>
                <Col xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>

                    <Button type="submit" className="mx-2" style={styles.btnBlue}>{t("commonFormLabels.submit")}</Button>

                    <Button variant="primary" style={styles.btnCancel}>
                      <Link to="/offers" style={{ textDecoration: "none", width: "100%", color: "#333", display: "block" }}>{t("commonHeader.cancel")}</Link>
                    </Button>

                  </div>
                </Col>
              </Row>
            </Form>
          </Container>
        </div>
      </Section>
    </>
  );
};

export default EditOffer;
