import { createSlice } from "@reduxjs/toolkit";

const initialState: any = {
    mainCategory : [],
    selectedMainCategory : {}
};

export const mainCategory = createSlice({
    name: "mainCategory",
    initialState,
    reducers: {
        SET_MAIN_CATEGORY_LIST: (state, action) => {
            state.mainCategory = action.payload;
        },
        SET_SELECTED_CATEGORY: (state, action) => {
            state.selectedMainCategory = action.payload;
        },
    },
});


export const { SET_MAIN_CATEGORY_LIST, SET_SELECTED_CATEGORY } = mainCategory.actions;
export default mainCategory.reducer;