import React, { useEffect, useRef, useState } from "react";
import { getStyles } from "./Orders.style";
import {
  Col,
  Image,
  Row,
  Button,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  ProgressBar,
  Badge,
  Modal,
  Form,
  InputGroup,
  FormControl,
  Overlay,
  Popover,
} from "react-bootstrap";
import ProductImage from "../../assets/images/ata.png";
import Completed from "../../assets/images/completed.png";
import Section from "../Section/Section";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/Store/Store";
import { Link, useNavigate } from "react-router-dom";
import {
  Add_To_Cart,
  Cancel_Order,
  fetchCancellationReasons,
  fetchOrderCancellationReasons,
  Past_Orders,
  Search_Store_Orders,
  View_Cart,
} from "../../Api";
import { base_url } from "../../Api/ApiConst";
import Sort from "../../assets/images/sort.png";
import noProduct from "../../assets/images/no-product.png";
import BroughtTogether from "../BroughtTogether/BroughtTogether";
import BuyAgain from "../BuyAgain/BuyAgain";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { VIEW_CART } from "../../Redux/Cart";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faBoxes,
  faTruck,
  faHome,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import NoDataFound from "../NoDataFound/NoDataFound";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import axios from "axios";

const Orders: React.FC = () => {
  const { t, i18n } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const styles = getStyles(isMobile);
  const [loading, setLoading] = useState<boolean>(false);
  // const [mainId,setMainId] = React.useState<string | null>("");
  const [stores, setStores] = React.useState<any>([]);
  const [userToken, setUserToken] = React.useState<string | null>("");
  const languageId: any = useSelector((state: RootState) => state.lan);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [pages, setPages] = useState<any>({});
  const [pastOrderData, setPastOrderData] = useState<any>("");
  // const [parameter, setParameter] = useState<any>("past");
  const [currentPosition, setCurrentPosition] = useState<any>("");
  const [showOrders, setShowOrders] = useState<boolean>(true); // State to manage showing orders or tracking
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<any>("");
  const [sortBy, setSortBy] = useState<string>("");
  const [showSortPopover, setShowSortPopover] = useState(false);
  const target = useRef(null);
  const [storeId, setStoreId] = useState<string | null>("");
  const [isSearchResult, setIsSearchResult] = useState<boolean>(false); // Track if the current view is search results

  const sortData = [
    {
      id: 1,
      Sorttype: "sub_total",
      name: "Order Amount (Ascending)",
      Ascending: true,
    },
    {
      id: 2,
      Sorttype: "sub_total",
      name: "Order Amount (Descending)",
      Ascending: false,
    },
    {
      id: 3,
      Sorttype: "order_date",
      name: "Order Date (Ascending)",
      Ascending: true,
    },
    {
      id: 4,
      Sorttype: "order_date",
      name: "Order Date (Descending)",
      Ascending: false,
    },
  ];


  const fetchOrders = async () => {
    try {
      setLoading(true);
      const response = await Past_Orders(currentPage);
      if (response && response.status === 200) {
        // console.log(response.data.store_list.language_data[0].store_name);
        console.log(response.data, "orders---------->");
        setPages(response.data.count);
        setStores(response?.data?.user_order_list);
      } else {
        console.log("Error fetching Nearest Shops");
      }
    } catch (error) {
      console.log("Error fetching Nearest Shops", error);
    } finally {
      setLoading(false);
    }
  };

  const getOrderStatus = () => {
    let selectedSelectedOrderStatusCode = pastOrderData?.status;

    if (selectedSelectedOrderStatusCode == "PENDING") {
      setOrderStatus("order_today");
      setProgress(25);
    } else if (selectedSelectedOrderStatusCode == "UNDERWAY") {
      setOrderStatus("packing");
      setProgress(50);
    } else if (selectedSelectedOrderStatusCode == "COMPLETE") {
      setOrderStatus("out_of_delivery");
      setProgress(75);
    } else if (selectedSelectedOrderStatusCode == "delivered") {
      setOrderStatus("arriving");
      setProgress(100);
    }
    // else if (selectedSelectedOrderStatusCode == "Cancelled") {
    //   setOrderStatus("order_today");
    //   setProgress(25);
    // }
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage, showOrders]);

  // useEffect(() => {
  //   fetchOrders();
  // }, [showOrders]);

  useEffect(() => {
    setUserToken(localStorage.getItem(STORAGE_CONSTANTS.USER_TOKEN));
    setStoreId(localStorage.getItem(STORAGE_CONSTANTS.STORE_ID));
  }, []);

  // const handleNext = () => {
  //   setCurrentPage(currentPage + 1);
  // };

  // const handleBack = () => {
  //   setCurrentPage(currentPage - 1);
  // };

  const fetchSearchOrders = async () => {
    try {
      const payload: any = {
        product_name: searchText,
        store_based: false,
      };
      if (!isNaN(searchText)) {
        payload["id"] = searchText;
      }

      const response = await Search_Store_Orders(payload, currentPage);
      if (response && response.status === 200) {
        console.log(response.data, "search------------------->");
        // setPages(response.data);
        // setCurrentPage(parseInt(response.data.count));
        setStores(response?.data?.user_order_list);
      } else {
        console.log("Error fetching Store Product Details");
      }
    } catch (error) {
      console.log("Error fetching Store Product Details", error);
    }
  };

  const Buy_Again = async (item: any, parameter: any) => {
    console.log(item, "item---------->");

    const addProductToCart = async (ele: any) => {
      console.log(ele, "ele========>");
      try {
        const cart: any = await Add_To_Cart(
          ele?.product?.id,
          "NPR",
          ele?.quantity
        );
        console.log(cart, "carts=======>");
        if (cart?.data?.status === true) {
          localStorage.setItem(
            STORAGE_CONSTANTS.ORDER_ID,
            cart?.data?.order_id
          );
        }
        toast.success("Products Added To Cart");
      } catch (error) {
        console.error("Error adding product to cart:", error);
        toast.error("Product is unavailable right now!");
      }
    };

    const details = item?.order_details || [];
    console.log(item, "details===========>");

    for (const detail of details) {
      await addProductToCart(detail);
    }

    const Cartresponse = await View_Cart();
    if (Cartresponse && Cartresponse.status === 200) {
      console.log(Cartresponse.data, "kkkkkkkkkkkk");
      dispatch(VIEW_CART(Cartresponse.data));
    }
  };

  const handleViewOrder = (item: any) => {
    const state = {
      id: item.id,
    };
    navigate("/view-past-order", { state: state });
  };
  // Track Order
  const [orderStatus, setOrderStatus] = useState("order_today");
  const [progress, setProgress] = useState(25);
  // const [showOrders, setShowOrders] = useState<boolean>(true); // State to manage showing orders or tracking

  const handleTrackOrder = (item: any) => {
    setShowOrders(false);
    setPastOrderData(item); // Set showOrders to false to hide past orders and cancelled orders
    // setParameter("track")
  };
  const handleBackToOrders = () => {
    setShowOrders(true); // Update state to show Past Orders and Cancelled Orders
    setPastOrderData("");
    // setParameter("past")
  };

  const handleCancel = (item: any) => {
    setPastOrderData(item);
    setShowCancelModal(true)
  }

  useEffect(() => {
    getOrderStatus();
  }, [pastOrderData]);

  const getIconClass = (status: string): string => {
    const statusOrder = [
      "order_today",
      "packing",
      "out_of_delivery",
      "arriving",
    ];
    const currentIndex = statusOrder.indexOf(orderStatus);

    if (progress === 100) {
      return "complete";
    } else if (statusOrder.indexOf(status) < currentIndex) {
      return "complete";
    } else if (statusOrder.indexOf(status) === currentIndex) {
      return "complete";
    } else {
      return "incomplete";
    }
  };

  // State to manage modal visibility and cancellation reason
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [cancelReason, setCancelReason] = useState<string>("");
  const [reasonOptions, setReasonOptions] = useState<string[]>([]);
  const [selectedCancelReason, setSelectedCancelReason] = useState<string>("");
  const [customReason, setCustomReason] = useState<string>(""); // New state for custom reason

  // Function to fetch cancellation reasons
  useEffect(() => {
    const fetchReasons = async () => {
      try {
        const reasons = await fetchOrderCancellationReasons(); // Fetch directly
        setReasonOptions(reasons || []); // Update state with the fetched reasons
      } catch (error) {
        toast.error("Failed to fetch cancellation reasons.");
      }
    };

    if (showCancelModal) {
      fetchReasons();
    }
  }, [showCancelModal]);


  // Fetch cancellation reasons when the modal opens
  useEffect(() => {
    if (showCancelModal) {
      fetchCancellationReasons();
    }
  }, [showCancelModal]);

  const handleCancelOrder = async () => {
    let finalReason = selectedCancelReason; // Start with the selected reason

    // Check if "Other Reason" is selected
    if (selectedCancelReason === "Other Reason") {
      if (!customReason.trim()) {
        toast.error("Please provide a reason for cancellation."); // Validation error
        return; // Exit if the custom reason is empty
      }
      finalReason = `Other Reason: ${customReason.trim()}`; // Construct final reason with custom input
    }

    if (finalReason) {
      try {
        const payload = {
          order_id: pastOrderData?.id,
          cancel_val: finalReason, // Store final reason directly
        };

        const response: any = await Cancel_Order(payload);
        if (response?.data?.status && response?.data?.message) {
          setShowOrders(true);
          toast.success(response?.data?.message);
          fetchOrders();
          setSelectedCancelReason(""); // Reset selected reason
          setCustomReason(""); // Reset custom reason
        }
      } catch (error: any) {
        if (error?.message) {
          toast.error(error?.data?.message);
        }
      }
      setShowCancelModal(false);
    } else {
      toast.error("Please select a cancellation reason.");
    }
  };




  const handleSearch = () => {
    fetchSearchOrders();
    if (searchText === "") {
      setIsSearchResult(false);
      return fetchOrders();
    }
    setIsSearchResult(true);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSort = (item: any) => {
    setSortBy(item);
    if (item?.name) {
      const sortedProducts: any = stores.sort((a: any, b: any) => {
        const itemA =
          item.Sorttype === "order_date"
            ? moment(a[item.Sorttype])
            : a[item.Sorttype];
        const itemB =
          item.Sorttype === "order_date"
            ? moment(b[item.Sorttype])
            : b[item.Sorttype];

        if (item.Ascending) {
          return itemA - itemB;
        } else {
          return itemB - itemA;
        }
      });

      setStores(sortedProducts);
    } else {
      setStores((prev: any) => prev?.sort((a: any, b: any) => b.id - a.id));
    }
  };

  const handleOverlayClose = () => {
    setShowSortPopover(false);
  };
  // console.log(pastOrderData, "pastOrderData=========>");

  const handleNext = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleBack = () => {
    setCurrentPage(currentPage - 1);
  };

  return (
    <>
      <div>
        {showOrders && (
          <>
            <Row className="align-items-center justify-content-between mb-3 order-search">
              {!loading && stores.length !== 0 && (
                <>
                  <Col>
                    <InputGroup>
                      <FormControl
                        type="text"
                        placeholder={t("commonHeader.globalSearch")}
                        className="mr-sm-2 search-input"
                        value={searchText}
                        onChange={(e: any) => setSearchText(e.target.value)}
                        onKeyDown={handleKeyDown}
                      />
                      <Button
                        variant="outline-primary search-btn"
                        onClick={handleSearch}
                      >
                        <FaSearch />
                      </Button>
                    </InputGroup>
                  </Col>
                  <Col className="hide-xs">
                    <div className="d-flex align-items-center justify-content-end flex-row">
                      <div ref={target} style={{ position: "relative" }}>
                        <Button
                          title="Sort"
                          style={styles.btnSort}
                          variant="outline-primary"
                          onClick={() => setShowSortPopover(!showSortPopover)}
                        >
                          <Image src={Sort} className="sortImage" />
                        </Button>
                        <Overlay
                          show={showSortPopover}
                          target={target.current}
                          placement="left"
                          containerPadding={20}
                          container={document.body}
                          onHide={handleOverlayClose}
                          rootClose
                        >
                          <Popover id="popover-contained">
                            {/* <Popover.Header as="h3">Sort By</Popover.Header> */}
                            <Popover.Body>
                              <ul
                                style={{ listStyleType: "none", padding: 0 }}
                                className="sortPopover"
                              >
                                {sortData &&
                                  sortData.map((ele: any) => {
                                    return (
                                      <li>
                                        <Button
                                          variant="link"
                                          onClick={() => handleSort(ele)}
                                        >
                                          {ele?.name}
                                        </Button>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </Popover.Body>
                          </Popover>
                        </Overlay>
                      </div>
                    </div>
                  </Col>
                </>
              )}
            </Row>
            <Row className="align-items-center justify-content-between past-order-wrap mb-3">
              <Col>
                <h2 style={styles.title} className="mb-0">
                  {t("userProfile.pastOrders")}
                </h2>
              </Col>
            </Row>
            <Section className="orders" style={{ paddingTop: 0 }}>
              {loading && (
                <Row>
                  <Col className="d-flex align-items-center justify-content-center">
                    <Spinner animation="grow" variant="primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </Col>
                </Row>
              )}
              {!loading && stores.length === 0 && (

                <NoDataFound title={t("userProfile.noOrdersFound")} />
              )}
              {!loading &&
                stores &&
                stores.map((item: any) => {
                  return (
                    <div style={styles.productRow} className="order-wrap">
                      <Row className="align-items-center">
                        <Col sm={2} className="imageCol">
                          <div style={styles.productImageWrap}>
                            <Image
                              src={
                                `${base_url}/${item?.order_details[0]?.product?.product_image}` ||
                                ProductImage
                              }
                              style={styles.productImage}
                            />
                          </div>
                        </Col>
                        <Col sm={4} className="infoCol">
                          <h2 style={styles.productName}>
                            {item?.order_details[0]?.product?.product_name}
                          </h2>
                          <p style={styles.qty}>
                            {t("userProfile.qty")}:{" "}
                            {item?.order_details[0]?.quantity}
                          </p>
                          <p style={styles.wt}>
                            {item?.order_details[0]?.weight} kg
                          </p>
                        </Col>
                        <Col sm={2} className="priceCol">
                          <div style={styles.priceWrap}>
                            <span style={styles.offerPrice}>
                              ₹{parseFloat(item?.total_amount).toFixed(2)}
                            </span>
                          </div>
                        </Col>
                        <Col sm={4} className="statusCol  hide-xs hide-sm">
                          <div className="d-flex align-items-end justify-content-end mb-2" style={{ flexDirection: "column" }}>
                            <h2 style={{ ...styles.completed, color: "#333" }} className="mb-2">#Order Id : {item?.id} </h2>
                            <h2 style={styles.completed}>{item?.status} </h2>
                            {/* <Image
                            src={Completed}
                            style={styles.completedImage}
                          /> */}
                          </div>
                          {/* <h3 style={styles.timeDate}>
                          Delivered on Fri, Apr 28, 2023
                        </h3> */}
                        </Col>
                      </Row>
                      <Row className="mt-3 btnRowWrap">
                        <Col
                          className="align-items-center justify-content-start"
                          style={styles.btnWrap}
                        >
                          <Button
                            style={styles.viewOrder}
                            onClick={() => handleViewOrder(item)}
                          >
                            {t("userProfile.viewOrder")}
                          </Button>
                          <Button
                            style={styles.buyAgain}
                            onClick={() => Buy_Again(item, "past")}
                          >
                            {t("userProfile.buyAgain")}
                          </Button>
                          {item?.status !== "Cancelled" && (
                            <Button
                              className="track-order"
                              style={styles.viewOrder}
                              onClick={() => handleTrackOrder(item)}
                            >
                              {t("trackOrder.trackOrder")}
                            </Button>
                          )}
                          <Button
                            style={styles.buyAgain}
                            onClick={() => handleCancel(item)}
                            disabled={item?.status !== "NEW"} // Disable button if the status is not "New"
                          >
                            {t("trackOrder.cancelOrder")}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              {!loading && stores.length >= 10 && (
                <div
                  className="xs-wrap"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    float: "right",
                  }}
                >
                  <nav aria-label="Page navigation example">
                    <ul className="pagination">
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={() =>
                            setCurrentPage(Math.max(currentPage - 1, 1))
                          }
                        >
                          {t("userProfile.previous")}
                        </a>
                      </li>
                      {Array.from({ length: pages }).map((_, index) => (
                        <li
                          className={`page-item ${currentPage === index + 1 ? "active" : ""
                            }`}
                          key={index}
                        >
                          <a
                            className="page-link"
                            onClick={() => setCurrentPage(index + 1)}
                          >
                            {index + 1}
                          </a>
                        </li>
                      ))}
                      <li className="page-item">
                        <a
                          className="page-link"
                          onClick={() =>
                            setCurrentPage(Math.min(currentPage + 1, pages))
                          }
                        >
                          {t("userProfile.next")}
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              )}
            </Section>
          </>
        )}
        {!showOrders && (
          <Section className="tracking">
            <Row>
              <Col>
                <div className="tracking-header-row">
                  <Button
                    variant="link"
                    onClick={() => handleBackToOrders()}
                    style={{ padding: 0 }}
                  >
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="arrow-left"
                    />
                  </Button>
                  <h2 style={{ ...styles.title, marginBottom: 0 }}>
                    {t("trackOrder.trackOrder")}
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex flex-row align-items-start justify-content-end">
                  {/* <h2 className="status-label">{orderStatus}</h2> */}
                  <div className="trackProductImageWrap">
                    <Image
                      src={
                        `${base_url}/${pastOrderData?.order_details[0]?.product?.product_image}` ||
                        ProductImage
                      }
                      className="trackProductImage"
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card>
                  <CardBody className="tracker-body">
                    <ul className="tracking-steps icon-wrap">
                      <li className={getIconClass("order_today")}>
                        <div>
                          <FontAwesomeIcon icon={faCheck} className="icon" />
                        </div>
                        <span className="label">
                          {t("trackOrder.orderToday")}
                        </span>
                      </li>
                      <li className={getIconClass("packing")}>
                        <div>
                          <FontAwesomeIcon icon={faBoxes} className="icon" />
                        </div>
                        <span className="label">{t("trackOrder.packing")}</span>
                      </li>
                      <li className={getIconClass("out_of_delivery")}>
                        <div>
                          <FontAwesomeIcon icon={faTruck} className="icon" />
                        </div>
                        <span className="label">
                          {t("trackOrder.outOfDelivery")}
                        </span>
                      </li>
                      <li className={getIconClass("arriving")}>
                        <div>
                          <FontAwesomeIcon icon={faHome} className="icon" />
                        </div>
                        <span className="label">
                          {t("trackOrder.arriving")}
                        </span>
                      </li>
                    </ul>
                    <ProgressBar now={progress} label={`${progress}%`} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col
                className="align-items-center justify-content-start"
                style={styles.btnWrap}
              >
                {/* <Button style={styles.viewOrder}>Delivery Instruction</Button> */}

                <Button
                  style={styles.viewOrder}
                  onClick={() => setShowCancelModal(true)}
                >
                  {t("trackOrder.cancelOrder")}
                </Button>
                <Button
                  style={styles.buyAgain}
                  onClick={() => Buy_Again(pastOrderData, "track")}
                >
                  {t("trackOrder.buyAgain")}
                </Button>
              </Col>
            </Row>
            <hr className="my-5"></hr>
            <Row>
              <Col>
                <div className="shipping-info">
                  <h2 className="mb-0">{t("trackOrder.shippingAddress")}</h2>
                  <h3 className="mb-0">{`${pastOrderData?.shipping_data?.first_name} ${pastOrderData?.shipping_data?.last_name}`}</h3>
                  <p>
                    {pastOrderData?.shipping_data?.street_address},{" "}
                    {pastOrderData?.shipping_data?.country}
                  </p>
                  <p>
                    {`${pastOrderData?.shipping_data?.City},${pastOrderData?.shipping_data?.post_code}`}
                  </p>
                  <p style={{ fontWeight: "600" }}>
                    +91 {pastOrderData?.shipping_data?.phone}
                  </p>
                </div>
              </Col>
            </Row>
          </Section>
        )}

        {/* Cancel Order Modal */}
        <Modal
          show={showCancelModal}
          onHide={() => setShowCancelModal(false)}
          centered
          className="cancelOrderModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("cancelOrder.cancelOrder")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="cancelReason">
                <Form.Label>
                  {t("cancelOrder.reasonForCancellation")}:
                </Form.Label>
                <Form.Control
                  as="select"
                  value={selectedCancelReason}
                  onChange={(e) => setSelectedCancelReason(e.target.value)}
                >
                  <option value="">Select a reason</option>
                  {reasonOptions.map((reason) => (
                    <option key={reason} value={reason}>
                      {reason}
                    </option>
                  ))}
                </Form.Control>

                {selectedCancelReason === "Other Reason" && (
                  <Form.Group controlId="customReason" className="mt-2">
                    <Form.Label>Please specify:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={customReason}
                      onChange={(e) => setCustomReason(e.target.value)}
                      placeholder="Enter your reason"
                    />
                  </Form.Group>
                )}
              </Form.Group>
              <Link className="m-2 mt-4" to="/privacy-policy" target="_blank">Click here for Cancellation Policy
              </Link>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btnCancel"
              onClick={() => setShowCancelModal(false)}
            >
              {t("cancelOrder.no")}
            </Button>
            <Button className="btnBlue" onClick={() => handleCancelOrder()}>
              {t("cancelOrder.yes")}
            </Button>
          </Modal.Footer>
        </Modal>

      </div >
    </>
  );
};

export default Orders;
