// Login.style.ts

import { CSSProperties } from "react";

export interface Styles {
    switchModal:CSSProperties;
}

const baseStyles: Styles = {
    switchModal:{
        background:"red"
    }

};

const mobileStyles: Partial<Styles> = {
    
  };
  export const getStyles = (isMobile: boolean): Styles => {
    if (isMobile) {
      return {
        ...baseStyles,
        ...mobileStyles,
      };
    }
    return baseStyles;
  };
