import React, { useEffect, useState } from "react";
import Section from "../../Components/Section/Section";
import {
  Button,
  Col,
  Container,
  Image,
  Row,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Modal,
  Card,
  Accordion,
  Spinner,
} from "react-bootstrap";
import Heading from "../../Components/Heading/Heading";
import { getStyles } from "./ConfirmOrder.style";
import { FaArrowRight, FaRegClock } from "react-icons/fa";
import ShopIcon from "../../assets/images/shop2.png";
import OfferIcon from "../../assets/images/percent.png";
import ProductImage from "../../assets/images/aata.png";
import BillingIcon from "../../assets/images/billingInfo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { COD_Payment, Payment_Status, View_Cart } from "../../Api";
import { base_url } from "../../Api/ApiConst";
import { toast } from "react-toastify";
import { CLEAR_CART } from "../../Redux/Cart";
import { useDispatch, useSelector } from "react-redux";
import { SET_PAYMENT_LINK } from "../../Redux/Payment";
import { RootState } from "../../Redux/Store/Store";
import { SET_USER_ADDRESS } from "../../Redux/User";
import { STORAGE_CONSTANTS } from "../../shared/Constants/Constants";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import Free from "../../assets/images/free.png";

import "react-datepicker/dist/react-datepicker.css";

const ConfirmOrder: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { t, i18n } = useTranslation();
  const styles = getStyles(isMobile);
  const breadcrumbs: string[] = ["Home", "Cart"];

  const [selectedMethod, setSelectedMethod] = useState<string>("");
  const [products, setProducts] = useState<any>('');
  const [deliveryNote, setDeliveryNote] = useState<any>('');
  const [amount, setAmount] = useState<any>("");
  const [payment, setPayment] = useState<any>("");
  const [uuid, setUuid] = useState<any>("");
  const [deliveryDate, setDeliveryDate] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userAddress: any = useSelector(
    (state: RootState) => state.User.UserAddress
  );

  const handleMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMethod(event.target.value);
  };

  const {
    first_name,
    last_name,
    address_str,
    city,
    pincode,
    email,
    mobile,
    state,
  } = userAddress;

  const Cart_Details = async () => {
    try {
      setLoading(true);
      const response = await View_Cart();
      if (response && response.status === 200) {
        console.log(response.data, "hgggggggggggggggggggggggj");
        setProducts(response.data.cart_details.order.order_list);
        setAmount(response.data.cart_details);
        // setStores(response.data.store_list);
      } else {
        console.log("Error fetching Cart details");
      }
    } catch (error: any) {
      console.log("Error fetching Cart details", error);
    } finally {
      setLoading(false);
    }
  };

  const PaymentStatus = async () => {
    if (uuid) {
      try {
        const response = await Payment_Status(uuid);
        if (response && response.status === 200) {
          console.log(response.data, "status--------------->");

          if (response.data.payment_status === false) {
            toast.error("Payment failed");
            localStorage.removeItem(STORAGE_CONSTANTS.UUID);
          }
          // setProducts(response.data.cart_details.order.order_list);
          // setAmount(response.data.cart_details);
          // setStores(response.data.store_list);
        } else {
          console.log("Error fetching Payment status");
        }
      } catch (error: any) {
        console.log("Error fetching Payment status", error);
      }
    } else {
      console.log("submit order");
    }
  };

  useEffect(() => {
    Cart_Details();
    setUuid(localStorage.getItem(STORAGE_CONSTANTS.UUID));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (uuid) {
      PaymentStatus();
    }
  }, [uuid]);

  const handlePaymentCOD = async (id: any) => {
    if (selectedMethod && deliveryDate) {
      try {
        const payload = {
          currency: "NPR",
          // code: "OFF21",
          order_id: id,
          submit: true,
          first_name: first_name || "",
          last_name: last_name || "",
          email: email || "",
          mobile_number: mobile || "",
          address: address_str || "",
          city: city || "",
          pincode: parseInt(pincode) || "",
          country: "nepal",
          state: state || "",
          payment_gateway: selectedMethod,
          gateway_data: {},
          success_val: true,
          delivery_date: deliveryDate.toISOString(),
          order_note: deliveryNote ? deliveryNote : ""
        };

        console.log(payload, "payload==========>");

        const response = await COD_Payment(payload);

        if (response && response.status === 200) {
          console.log(response.data, "COD----------->");
          setPayment(response?.data?.esewa_payment_url);
          localStorage.setItem(
            STORAGE_CONSTANTS.UUID,
            response?.data?.esewa_payment_url
          );

          dispatch(CLEAR_CART());
          if (selectedMethod === "cod") {
            navigate("/thank-you");
            toast.success("Order placed successfully");
          }

          if (selectedMethod === "eSewa") {
            const url: any = response.data.esewa_payment_url + "&web=true";
            const paymentWindow = window.open(url, "_self");


          }
          else if (selectedMethod === "khalti") {
            const url: any = response.data.khalti_payment_url + "&web=true";
            const paymentWindow = window.open(url, "_self");


          } else {
            console.log("error");
          }
        } else {
          console.log("Error fetching Payment details");
          toast.error(response.data.message);
        }
      } catch (error) {
        console.log("Failed to place order", error);
        toast.error("Failed to place order. Please try again.");
      }
    } else {
      toast.error("Please select a payment method to place your order");
    }
  };

  // const handleCoupons = async (coupon: any) => {
  //   try {
  //     const payload = {
  //       "currency": "NPR",
  //       "coupon": coupon,
  //       "order_id": orderId,
  //       "submit": false,
  //     };
  //     const response: any = await COD_Payment(payload);

  //     if (!response?.error) {
  //       // setStoreRevenue(response.data?.revenue_data?.total_amount ?? 0);
  //       console.log("data----->", response.data);
  //       const Cartresponse = await View_Cart();
  //       if (Cartresponse && Cartresponse.status === 200) {
  //         console.log(Cartresponse.data, "kkkkkkkkkkkk");
  //         // dispatch(VIEW_CART(Cartresponse.data));
  //         // console.log(Cartresponse.data,"99999999999999")
  //       }
  //     }
  //     // setShowLoader(false);
  //   } catch (error) {
  //     // setShowLoader(false);
  //     console.log(error);
  //   }
  // };

  console.log(uuid, "uuid----------->");

  // {loading && (
  //   <Row>
  //     <Col className="d-flex align-items-center justify-content-center">
  //       <Spinner animation="grow" variant="primary" role="status">
  //         <span className="visually-hidden">Loading...</span>
  //       </Spinner>
  //     </Col>
  //   </Row>
  // )}

  return (
    <>
      <Section className="viewCart">
        <Container>
          <Row>
            <Col>
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <React.Fragment key={index}>
                    <span
                      style={{
                        color: index === 0 ? "var(--primary-color)" : "#BEB8B8",
                        fontFamily: "var(--primary-font-medium)",
                        fontSize: 16,
                      }}
                    >
                      {crumb}
                    </span>
                    {index !== breadcrumbs.length - 1 && (
                      <FaArrowRight
                        className="mx-2"
                        style={{ color: "#BEB8B8" }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="py-4">
            <Heading text={t("cart.confirmOrder")} alignment="left" />
          </Row>
        </Container>
        <div style={styles.whiteWrap}>
          {loading && (
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <Spinner animation="grow" variant="primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          )}
          {!loading && (
            <Container className="below">
              <Row style={styles.cartFirst} className="cart-row">
                <Col
                  sm={7}
                  style={styles.cartLeftCol}
                  className="cart-col-left"
                >
                  <div style={styles.cartTitle}>
                    {/* <p className="mb-1"> {t("cart.getItBy")} </p> */}
                    <Form.Group controlId="deliveryDate">
                      <Form.Label>{t("cart.getItBy")}</Form.Label>&nbsp;&nbsp;
                      <DatePicker
                        selected={deliveryDate}
                        onChange={(date: Date) => setDeliveryDate(date)}
                        minDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                      />
                    </Form.Group>
                    {/* <h3 className="mb-1" style={styles.day}>
                    Monday
                  </h3> */}

                    {/* <h3 className="mb-1" style={styles.deliveryCharge}>
                    {" "}
                    ₹40.00 Delivery
                  </h3>
                  <h2 className="mb-1" style={styles.deliveryDate}>
                    <b> {t("cart.guaranteedDeliveryOn")} </b> 11 Dec 2023
                  </h2> */}
                  </div>

                  <div style={styles.cartItem}>
                    {/* <h3 style={styles.cartTitle}>Shipping From</h3> */}
                    {Object.values(products).map((item: any) =>
                      item.map((ele: any) => {
                        const isFreeProduct = ele?.is_free_product; // Check if the product is free
                        // console.log(ele, "ele-------->");
                        return (
                          <Row
                          className={`d-flex align-items-center cart-item-row ${isFreeProduct ? "free-product" : ""
                            }`}
                                                        style={styles.oneCart}
                          >
                            <Col sm={2} className="cartImageCol">
                            {ele?.is_free_product ? (
                                    <div className="freeIcon">
                                      <Image
                                      src={Free}
                                      
                                      // style={styles.productImage}
                                    /></div>
                                ) : null}
                              <div style={styles.productImageWrap}>
                                <Image
                                  src={
                                    `${base_url}/${ele?.product?.product_image}` ||
                                    ProductImage
                                  }
                                  style={styles.productImage}
                                />
                              </div>
                            </Col>
                            <Col sm={4} className="cartProductInfoCol">
                            
                              <div style={styles.productNameWt}>
                                <h3 style={styles.productName}>
                                  {ele?.product?.product_name}
                                </h3>
                                <h2 style={styles.productWt}>
                                  {ele?.product?.PRDWeight} kg
                                </h2>
                              </div>
                            </Col>
                            <Col sm={3} className="cartQuantityCol">
                              <div
                                className="add-to-cart"
                                style={{ borderRadius: 10 }}
                              >
                                <h2 style={styles.cartQty}>
                                  {" "}
                                  {t("userProfile.qty")} : {ele?.quantity}
                                </h2>
                              </div>
                            </Col>
                            <Col sm={3} className="cartPriceCol">
                              <div style={styles.productPrice}>
                                ₹{ele?.price}
                              </div>
                            </Col>
                          </Row>
                        );
                      })
                    )}
                  </div>
                </Col>
                <Col
                  sm={5}
                  style={styles.cartRightCol}
                  className="cart-col-right"
                >
                  <h3 style={styles.cartTitle}>{t("cart.orderSummary")}</h3>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span style={styles.value500}>
                      {t("cart.subTotal")} ({amount?.cart_items} items)
                    </span>
                    <span style={styles.value600}>₹{amount?.f_total}</span>
                  </div>
                  {amount?.order?.order_details?.discount > 0 && (
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span style={styles.value500}>
                        {t("cart.itemSavings")}
                      </span>
                      <span style={{ ...styles.value600, color: "green" }}>
                        - ₹{Math.floor(amount?.order?.order_details?.discount)}
                      </span>
                    </div>
                  )}
                  {amount?.delivery_charge ? (
                    <div className="d-flex align-items-center justify-content-between mb-2">
                      <span style={styles.value500}>Delivery Partner Fee</span>
                      <span style={styles.value600}>₹{amount.delivery_charge}</span>
                    </div>
                  ) : null}

                  <hr />
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span style={styles.value600}>{t("cart.orderTotal")}</span>
                    <span style={styles.value600}>
                      ₹{amount?.total}
                      {/* {amount?.f_total !== amount?.total && (<>
                        &nbsp;&nbsp; <span
                          style={{
                            ...styles.value500,
                            color: "#808080",
                            textDecorationLine: "line-through",
                          }}
                        >
                         ₹{amount?.f_total}
                        </span></>
                      )} */}
                    </span>
                  </div>

                  <div>
                    <h3 style={styles.cartTitle}>
                      {t("cart.deliveryAddress")}
                    </h3>
                  </div>
                  <div
                    style={styles.selectedAddressWrap}
                    className="py-4 selectedAddressWrap"
                  >
                    <div style={styles.selectedAddressText}>
                      <div style={styles.selectedAddressTitle}>
                        {first_name &&
                          last_name &&
                          `${first_name} ${last_name}`}
                      </div>
                      {address_str}
                      <br></br>
                      {`${city},${pincode}`}
                      <br></br>
                      {mobile}
                    </div>
                  </div>
                  <div className="mt-3">
                    <Form.Label style={styles.cartTitle}>
                      {t("viewOrder.deliveryNote")}
                    </Form.Label>
                    <div>
                      <textarea
                        className="form-control"
                        value={deliveryNote}
                        onChange={(e) => setDeliveryNote(e.target.value)}
                      />                   </div>
                  </div>
                  <div style={styles.paymentMethodWrap} className="mt-3">
                    <Form className="paymentRadio">
                      <Form.Group as={Col} controlId="paymentMethod">
                        <Form.Label style={styles.cartTitle}>
                          {t("cart.selectPaymentMethod")}
                        </Form.Label>
                        <div key={`inline-radio`} className="mb-3">
                          {/* <Form.Check
                            inline
                            label={t("cart.online")}
                            type="radio"
                            id={`inline-radio-1`}
                            value="online_payment"
                            checked={selectedMethod === "eSewa"}
                            onChange={handleMethodChange}
                          /> */}
                          <Form.Check
                            inline
                            label={"E-sewa"}
                            type="radio"
                            id={`inline-radio-1`}
                            value="eSewa"
                            checked={selectedMethod === "eSewa"}
                            onChange={handleMethodChange}
                          />
                          <Form.Check
                            inline
                            label={"Khalti"}
                            type="radio"
                            id={`inline-radio-1`}
                            value="khalti"
                            checked={selectedMethod === "khalti"}
                            onChange={handleMethodChange}
                          />
                          <Form.Check
                            inline
                            label={t("cart.cod")}
                            type="radio"
                            id={`inline-radio-2`}
                            value="cod"
                            checked={selectedMethod === "cod"}
                            onChange={handleMethodChange}
                          />
                        </div>
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="justify-content-center d-flex mt-3">
                    <Button
                      className="btnBlue"
                      style={{ minWidth: 250 }}
                      onClick={() => handlePaymentCOD(amount?.order?.id)}
                    >
                      {t("cart.placeOrder")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </Section>
    </>
  );
};

export default ConfirmOrder;
